


















































import * as vr from '@/helpers/validation';
import { ValidationRules } from '@/api/schema';
import Tooltip from '@/components/tooltip/Tooltip.vue';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';

export default {
  name: 'PromoCampaignFormCompany',
  mixins: [PromoCampaignFormMixin],

  components: {
    Tooltip
  },

  computed: {
    rules(): ValidationRules {
      return {
        audienceRadioGroup: [vr.required]
      };
    }
  }
};
